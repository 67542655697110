import { useRouter } from "next/router";
import { useEffect } from "react";

import { WORK_TYPE_LAWYER } from "../config";
import { useAccount } from "../data/dataHooks";
import { routeOnboarding } from "../routes";

/**
 * Redirect to onboarding if required, redirect back to home if the user is onboarded and on the onboarding page
 */
export function useOnboardingGate(): boolean {
  const router = useRouter();
  const { data: account } = useAccount();

  const onboarded =
    // Default to onboarded while we wait for the first data, most users will be onboarded so this is a sensible default
    account === undefined ||
    (account.profile !== null && account.profile.workType === WORK_TYPE_LAWYER);

  useEffect(() => {
    if (account === undefined) {
      // Do nothing until account data is loaded
      return;
    }

    if (
      !onboarded &&
      // Prevent redirection loop
      !router.pathname.startsWith(routeOnboarding().pathname)
    ) {
      router.push(routeOnboarding()).catch((e) => {
        console.error("Failed to push onboarding route", e);
      });
    }
  }, [account, router, onboarded]);

  return onboarded;
}
