import { Box, Container, Stack } from "@mui/material";

import { DescSetupOffer } from "../src";
import { SetupOffer } from "../src";
import { getStandardLayout } from "../src/layouts/StandardLayout";

import { NextPageWithLayout } from "./_app";

const Home: NextPageWithLayout = () => {
  return (
    <Box
      component="main"
      sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
    >
      <Container
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Stack
          gap={{ xs: 2, lg: 4 }}
          direction={{ xs: "column", md: "row" }}
          py={4}
        >
          <DescSetupOffer />
          <SetupOffer />
        </Stack>
      </Container>
    </Box>
  );
};

export default Home;

Home.getLayout = getStandardLayout;
