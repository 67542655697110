import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import * as Sentry from "@sentry/react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { usePathname } from "next/navigation";
import { ReactElement, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorBox } from "../components/ErroBox";
import { SUPPORT_EMAIL } from "../config";
import { useAccount } from "../data/dataHooks";
import { Footer } from "../Footer";
import { HeaderMenu } from "../HeaderMenu";
import { routerSafePush } from "../navigation/routerSafePush";
import { useOnboardingGate } from "../navigation/useOnboardingGate";
import { reportError } from "../reportError";
import { routeLogin, routeRegistrationProduct } from "../routes";

function StandardLayout({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<any | null | undefined>(undefined);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user != null) {
        setUser(user);
        Sentry.setUser({ id: user.uid });
      } else {
        Sentry.configureScope((scope) => scope.setUser(null));
        setUser(null);
        routerSafePush(routeLogin());
      }
    });
    return () => unsubscribe();
  }, []);

  const onboarded = useOnboardingGate();
  const { isError, error, refetch } = useAccount();
  const path = usePathname();

  return (
    <ErrorBoundary
      onReset={() => routerSafePush(routeRegistrationProduct())}
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <>
          <HeaderMenu logoOnly={!onboarded} />
          <Container>
            <Box
              sx={{
                textAlign: "center",
                flexGrow: 1,
                alignItems: "center",
                p: 6,
              }}
            >
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    <Typography variant={"h6"}>Oops !</Typography>
                    <Typography variant={"body1"}>
                      Un problème inattendu est survenu, contactez le support :{" "}
                      <Link href={"mailto:" + SUPPORT_EMAIL}>
                        {SUPPORT_EMAIL}
                      </Link>
                    </Typography>
                    <Alert severity={"error"}>{String(error)}</Alert>
                    <Button
                      sx={{ alignSelf: "center" }}
                      variant={"contained"}
                      onClick={resetErrorBoundary}
                    >
                      Retour au tableau de bord
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          </Container>
          <Container>
            <Footer />
          </Container>
        </>
      )}
      onError={(error) => {
        reportError(error);
        console.error(error);
      }}
    >
      <Box
        sx={{
          backgroundColor: grey[50],
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {user !== null ? (
          <>
            <HeaderMenu logoOnly={!onboarded} />
            {isError ? (
              <Container>
                <ErrorBox visible={true} error={error} onRetry={refetch} />
              </Container>
            ) : (
              <Container sx={{ pt: 10 }}>{children}</Container>
            )}
            {!path.includes("admin") && (
              <Container>
                <Footer />
              </Container>
            )}
          </>
        ) : (
          <Box sx={{ textAlign: "center", flexGrow: 1, alignItems: "center" }}>
            <Typography variant="body1">
              Vous n'êtes pas connecté, retour à l'accueil...
            </Typography>
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  );
}

export default StandardLayout;

export function getStandardLayout(page: ReactElement) {
  return <StandardLayout>{page}</StandardLayout>;
}
