import { Alert, AlertTitle, Button, Typography } from "@mui/material";

interface Props {
  visible: boolean;
  error: any;
  onRetry?: () => void;
  message?: string;
  showErrorDetails?: boolean;
}

export const ErrorBox = (props: Props) => {
  if (!props.visible) {
    return null;
  }

  return (
    <Alert
      sx={{ mt: 2, textAlign: "left" }}
      severity="error"
      action={
        props.onRetry !== undefined ? (
          <Button color="inherit" size="small" onClick={props.onRetry}>
            Réessayer ?
          </Button>
        ) : undefined
      }
    >
      <AlertTitle>
        {props.message ?? "Oops, une erreur est survenue"}
      </AlertTitle>
      {props.showErrorDetails !== false && (
        <Typography variant="body2" sx={{ fontSize: 10 }}>
          {String(props.error)}
        </Typography>
      )}
    </Alert>
  );
};
